<template>
  <div class="app">
    <header-component ref="header" />
    <div class="app-inner">
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
export default {
  name: "Home",
  components: {
    HeaderComponent,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_var";
.app {
  padding: 15px;
  background-color: #f4f4f4;
  .app-inner {
    max-width: 100%;
    width: $theme-width;
    margin: 0 auto;
  }
}
@media (max-width: 700px) {
  .app {
    padding: 10px;
  }
}
</style>
